.purchase_card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1.5rem;
  border-radius: 0.9rem;
}

.purchase_card .purchase_card_header p {
  color: #292929;
  font-size: 1.2rem;
  font-weight: 500;
}

.purchase_card .purchase_card_header h6 {
  color: #202224;
  font-size: 1.7rem;
  font-weight: 600;
}

.purchase-card-header {
  border-radius: 0.9rem !important;
}

.purchase-dashboard-navigate-button :hover {
  text-shadow: red;

}

.project_review-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 0.8rem;
  margin-top: 2rem;
  padding: 1rem;
}

.project_review-card .review-card p {
  font-size: 1rem;
  color: black;
}
.project_review-card .review-card {
  border-radius: 1rem 1rem 0 0;
}


.project_review-card .review-card h6 {
  font-size: 1.4rem;
  font-weight: 500;
  color: black;
}

.project_review-card .review_date p {
  font-size: 1rem;
  color: black;
}

.project_review-card .review_date span {
  font-size: 1rem;
  color: black;
  font-weight: 500;
}

.bg-gray-document {
  background-color: #f3f3f3;
  padding: 0.5rem 1.5rem;
  margin: 1rem;
  border-radius: 0.6rem;
}

.fixed-button {
  margin-bottom: 0rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  z-index: 9;
}

.bs-vertical-wizard {
  border-right: 1px solid #eaecf1;
  padding-bottom: 50px;
}

.bs-vertical-wizard ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bs-vertical-wizard ul>li {
  display: block;
  position: relative;
}

.bs-vertical-wizard ul>li>a {
  display: block;
  padding: 5px 10px 60px 63px;
  color: #333c4e;
  font-size: 21px;
  font-family: "Poppins";
  font-weight: 500;
  letter-spacing: 0.8px;
}

.bs-vertical-wizard ul>li>a:before {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% - 26px);
  background-color: #bdc2ce;
  left: 30px;
  bottom: -10px;
  z-index: 1;
}

.bs-vertical-wizard ul>li>a .ico {
  pointer-events: none;
  font-size: 40px;
  position: absolute;
  left: 9px;
  top: 6px;
  z-index: 1;
}

.bs-vertical-wizard ul>li>a:after {
  content: "";
  position: absolute;
  border: 2px solid #bdc2ce;
  border-radius: 50%;
  top: 12px;
  left: 23px;
  width: 16px;
  height: 16px;
  z-index: 1;
}

.bs-vertical-wizard ul>li>a .desc {
  display: block;
  color: #5b5b5b;
  font-size: 0.9rem;
  font-weight: 400;
  font-family: "Roboto";
}

.bs-vertical-wizard ul>li.complete>a:before {
  background-color: #5cb85c;
  opacity: 1;
  height: calc(95% - 9px);
  bottom: -28px;
}

.bs-vertical-wizard ul>li.complete>a:after {
  display: none;
}

.bs-vertical-wizard ul>li.locked>a:after {
  display: none;
}

.bs-vertical-wizard ul>li:last-child>a:before {
  display: none;
}

.bs-vertical-wizard ul>li.complete>a .ico {
  left: 8px;
}

.bs-vertical-wizard ul>li>a .ico.ico-green {
  color: #d1d1d1;
}

.bs-vertical-wizard ul>li>a .ico.ico-red {
  color: #d03438;
}

.bs-vertical-wizard ul>li>a .ico.ico-megenta {
  color: #656d9a;
}

.bs-vertical-wizard ul>li>a .ico.ico-blue {
  color: #0084ce;
}

.bs-vertical-wizard ul>li>a .ico.ico-gre {
  color: #06b48a;
}

.bs-vertical-wizard ul>li>a .ico.ico-muted {
  color: #bdc2ce;
}

.bs-vertical-wizard ul>li.current {
  background-color: #fff;
}

.bs-vertical-wizard ul>li.current>a:before {
  background-color: black;
  opacity: 1;
}

.bs-vertical-wizard ul>li.current>a:after {
  border-color: black;
  background-color: black;
  opacity: 1;
}

.bs-vertical-wizard ul>li.current:after,
.bs-vertical-wizard ul>li.current:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.bs-vertical-wizard ul>li.current:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 10px;
  margin-top: -10px;
}

.bs-vertical-wizard ul>li.current:before {
  border-color: rgba(234, 236, 241, 0);
  border-left-color: #eaecf1;
  border-width: 11px;
  margin-top: -11px;
}

.table-container-scroll {
  width: 100%;
  overflow: auto;
}

.table-container-scroll .common-table {
  table-layout: fixed;
}

.table-container-scroll .common-table tr {
  border-bottom: 1px solid black;
  padding: 0;
  margin: 0;
}

.table-container-scroll .common-table p {
  word-break: break-word;
}

.table-container-scroll .common-table h6 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-container-scroll .common-table td,
.table-container-scroll .common-table th {
  border: 1px solid black;
  border-top: none;
}

.table-container-scroll .common-table td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.table-container-scroll .table thead {
  display: block;
}

.table-container-scroll .table tbody {
  display: block;
  overflow-y: scroll;
  max-height: 600px;
  padding: 0;
}

.table-container-scroll .table tbody::-webkit-scrollbar {
  width: 12px;
}

.table-container-scroll .table tbody::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.table-container-scroll .table tbody::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.purchase-asidebar-accordian .accordion-item {
  background: none !important;
  border: none !important;
}

.purchase-asidebar-accordian .accordion-item .accordion-header {
  background: none !important;
  border: none !important;
}

.purchase-asidebar-accordian .accordion-item .accordion-header button {
  background: none !important;
  border: none !important;
  color: #fff;
  padding: 0.4rem 1rem;
  font-size: 1rem;
  border-radius: 8px;
  margin: 0.5rem 0px;
  color: #9da2af;
  font-weight: bold;
}

.purchase-asidebar-accordian .accordion-item .accordion-body {
  padding: 0 1rem !important;
}

.purchase-asidebar-accordian .accordion-item .accordion-body ul {
  padding-left: 0 !important;
}

.purchase-asidebar-accordian .accordion-item .accordion-body ul li a {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.purchase-asidebar-accordian .accordion-item .accordion-button:not(.collapsed) {
  color: #d03438;
  background-color: #fff !important;
}

/*# sourceMappingURL=PurchaseStyle.css.map */

/* Purchase Dashboard Table Css Start */
.purchase-dashboard-delivery-scheduled-table td {
  border-width: 1px;
}


//Delivery-Schedule-Styling

//delivery-schedule-filters-styling-by-Anu
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

// Delivery Schedule Filters Container 
.delivery-schedule-filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  flex-wrap: wrap;
}

// Title
.delivery-schedule-filters-title {
  font-size: 24px;
  font-weight: 600;
  color: #0f172a;
  font-family: "roboto";
}

// Controls Wrapper
.delivery-schedule-filters-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-end;
  padding-right: 1.2rem;
}

// Dropdown
.delivery-schedule-dropdown {
  width: 150px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #9198a4;
  background-color: #f0f4ff;
}

// Search Bar
.delivery-schedule-search {
  width: 200px;
  padding: 8px;
  margin-left: -15px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #9198a4;
  background-color: #ffffff;
  margin-right: 1rem;
}

// Date Picker
.delivery-schedule-calendar {
  width: 130px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #0f172a;
  background-color: #fff;
}

// Download Button
.delivery-schedule-download-button {
  background-color: #d03438;
  color: #fff;
  border: none;
  padding: 10px 40px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #b52d30;
  }
}

//delivery-schedule-table-styling
.delivery-schedule-heading {
  align-items: center;
  justify-content: center;

  background-color: #F4F4FB;

  font-weight: 500;

  tr {
    th {
      color: #8B909A;
      font-size: 12px;

      &:first-child {
        width: 5%;
        padding-right: 0;
      }

      &:nth-child(2) {
        width: 18%;
      }

      &:nth-child(3) {
        width: 16%;
      }

      &:nth-child(4) {
        width: 13%;
      }

      &:nth-child(5) {
        width: 16%;
      }

      &:nth-child(6) {
        width: 14%;
      }

      &:nth-child(7) {
        width: 8%;
      }

      &:nth-child(8) {
        width: 10%;
      }
    }

  }
}

.delivery-schedule-body {
  td {
    font-size: .825rem;
    border-bottom: 1px solid #ECECEC;
  }

}

/* Purchase Dashboard Table Css Start */
.purchase-dashboard-delivery-scheduled-table td {
  border-width: 1px;
  padding: 12px 12px 12px 12px;


}

.purchase-dashboard-delivery-scheduled-table th {
  border-width: 2px;
  padding: 12px 12px 12px 12px;
  color: #001E66;
  font-size: .9rem;
  font-weight: 700 !important;

}

.DeliveyTableStatusRed {
  color: red;
}

.DeliveyTableStatus {
  color: orange;
}

.DeliveyTableStatusBlue {
  color: blue;
}

.DeliveyTableStatusGreen {
  color: green;
}

/* Purchase Order preview Modal css start */
.modal-content .modal-body .purchaseOrderPreview p,
.modal-content h4 {
  color: black !important;
}

/* Purchase Order preview Modal css End */

/* Purchase Dashboard Table Css End */
/* Email verification modal css start */
.purchaseEmailVerificationModal fieldset {
  border: none;
}

/*  Email verification modal css end*/


//purchaseOrderListingDesignUpdateStylingByANU

.purchase-order-listing-heading th {
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #8B909A;
  background-color: #F4F4FB;
  border: none;
  border-radius: 5px;
}

.purchase-order-listing-body td {
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid #ECECEC;

}

.purchase-order-listing-heading>tr th:first-child {
  width: 5%;
  padding-right: 0;
}

.purchase-order-listing-heading>tr th:nth-child(2) {
  width: 14%;
}

.purchase-order-listing-heading>tr th:nth-child(3) {
  width: 15%;
}

.purchase-order-listing-heading>tr th:nth-child(4) {
  width: 9%;
}

.purchase-order-listing-heading>tr th:nth-child(5) {
  width: 11%;
}

.purchase-order-listing-heading>tr th:nth-child(6) {
  width: 11%;
}

.purchase-order-listing-heading>tr th:nth-child(7) {
  width: 11%;
}

.purchase-order-listing-heading>tr th:nth-child(8) {
  width: 10%;
}

.purchase-order-listing-heading>tr th:nth-child(9) {
  width: 10%;
}

.purchase-order-listing-heading>tr th:nth-child(10) {
  width: 8%;
}

.purchase-order-listing-heading>tr th:nth-child(11) {
  width: 8%;
}


/*  Email verification modal css end*/


//PO-approved-successByANU
.po-mail {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #FFFFFF;
  border-radius: 10px;
 
}
.po-content{
  padding:20px 20px 20px 20px;
}

.po-mail .img-fluid {
  border-radius: 10px; /* Apply the same border-radius to images */
}

//PO-decline-requestByANU
.po-decline-mail {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #FFFFFF;
  border-radius: 10px;
 
}
.po-decline-content{
  padding:20px 20px 20px 20px;
}

.po-decline-mail .img-fluid {
  border-radius: 10px; /* Apply the same border-radius to images */
}

//PO-ation-taken-requestByANU

.po-actionTaken-mail {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #FFFFFF;
  border-radius: 10px;
 
}
.po-actionTaken-content{
  padding:20px 20px 20px 20px;
}

.po-actionTaken-mail .img-fluid {
  border-radius: 10px; /* Apply the same border-radius to images */
}

//PO-linkExpired-ByANU

.po-linkExp-mail {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #FFFFFF;
  border-radius: 10px;
 
}
.po-linkExp-content{
  padding:20px 20px 20px 20px;
}

.po-linkExp-mail .img-fluid {
  border-radius: 10px; /* Apply the same border-radius to images */
}
  /*  Email verification modal css end*/ 
  
  // cancel Modal  In po listing start
  .hover-red:hover {
    color: red;
  }
  .cancel-modal {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    font-family: Arial, sans-serif;
  }
  
  .cancel-modal-header {
    display: flex;
    background-color: #0a1128;
    color: white;
    font-weight: bold;
  }
  
  .cancel-modal-row {
    display: flex;
    border-top: 1px solid #ccc;
  }
  
  .cancel-modal-cell {
    flex: 1;
    padding: 10px;
    text-align: left;
  }
  
  .cancel-modal-show-more {
    padding: 10px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    color: blue;
  }
  .cancel-modal-no-delivery {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    color: red;
    font-weight: bold;
  }
  
  
  // cancel Modal In po listing end

  //Purchase Ticket Css start
  .purchase-ticket-detail p{
    color: #404757;
    font-size: 1rem !important;
  }
  .purchaseTicketDetailValues{
     font-weight: 600;
  }
   //Purchase Ticket Css end
