body {
  font-family: "Poppins", sans-serif !important;
}

.App {
  font-family: "Poppins", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
select,
input,
option {
  font-family: "Poppins", sans-serif !important;
}

@media (min-width: 320px) and (max-width: 576px) {
}
@media (min-width: 576px) and (max-width: 768px) {
}
@media (min-width: 768px) and (max-width: 1200px) {
  .page-wrapper .responsive-tab {
    width: calc(100% - 300px) !important;
    margin-left: auto;
    position: relative;
    min-height: calc(100vh - 60px);
    padding: 0 8px 52px 8px;
    margin-top: 60px;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  html{
    font-size: 14px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  html{
    font-size: 11px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  html{
    font-size: 11px;
  }
}
.page-wrapper .responsive-tab {
  width: calc(100% - 300px) !important;
  margin-left: auto;
  position: relative;
  min-height: calc(100vh - 60px);
  padding: 0 8px 52px 8px;
  margin-top: 60px;
}

.enlarge-menu .purchase-asidebar {
  min-width: 300px !important;
}

.purchase-asidebar {
  min-width: 300px !important;
  max-width: 300px !important;
}

.historicCard p{
width: 10rem;
}
.historicCard span {
  margin-right: .8rem;
}

.historicCard h5{
 width: 60%;
}

/* Communication Tracker Css Start*/
/* Communication OverView Page Css */
.table-scroll-container {
  max-height: 76vh; /* Adjust height as needed */
  overflow-y: auto; /* Vertical scrollbar */
  overflow-x: auto; /* Horizontal scrollbar */
  padding: 1rem 0 1rem 0; /* Remove padding for a clean scrollable area */
}
.communication-table-header th {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0F172A;
  width: 10rem;
  border-radius: 10px;
  
}
.communication-table-header th:first-child  {
  width: 5rem !important;
}

.communication-table-detail {
  box-shadow: 0px 0px 15px 0px #00000005;
  margin-top: 10px;
  border: 1px solid #e1d7d7;
  background: #FFFFFF;


}
.communication-table-detail td{
font-family: Poppins;
font-size: 15px;
font-weight: 500 !important;
line-height: 22.5px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000;
word-break: break-all;

}

.communication-table {
  border: none;
  background-color: #F5F8FA;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 15px;
  border-collapse: separate; /* Use separate to allow spacing between rows */
  border-spacing: 0 15px; /* Add vertical gap between rows */
}

.communication-table caption {
  caption-side: bottom;
}


.communication-table tbody tr {
  margin-bottom: 15px; /* Adds spacing between rows */
}

.communication-status-button-green {
  background: #17AF7A40;
  color: #17AF7A;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  border: none;

  
}
.communication-status-button-red {
  background: #AF171740;
  color: #AF1717;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  border: none;


}
.communication-status-button-yellow {
  background: #FFEC4140;
  color: #F3BE00;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  border: none;

}
.communication-status-button-blue {
  background: #2196F340;
  color: #2196F3;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  border: none;

}
/* Communication OverView Page End */

/* Communication Detail page css Start */


.communication-table-detail-header th {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0F172A;
  width: 10rem;
  border-radius: 10px;
  
}

.communication-tracker-detail-table-detail td {
  padding: 2rem 1rem 2rem 1rem;
  border-bottom:  1px solid #E7E7E7;
  font-family: Poppins;
font-size: 15px;
font-weight: 500 !important;
line-height: 22.5px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000;
}
/* Communication Detail page css End */

/* Communication Tracker Css End*/
/* Financial table header sticky  */
.finacialTableHeader {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    text-align: left;
    display: block;
    height: calc(100% - 4rem);
    overflow-y: auto;
    border: none;}


/* Item Suggestion Css start */
/* Container for suggestions */
.suggestions-container {
  border: none;
  width: 100%;
  font-family: Arial, sans-serif;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;

}

/* Header Section */
.suggestions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #c9c9c9;
}

.title {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.item-count {
  font-size: 0.9rem;
  color: #555;
}

/* Suggestions List */
.suggestions-list {
  padding: 0;
  margin: 0;
  overflow: auto;
  max-height: 85vh;
}

.suggestion-item {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e5e5e5;
  color: #333;
  font-size: 0.9rem;
}

/* Last item no border */
.suggestion-item:last-child {
  border-bottom: none;
}

/* Hover effect for items */
.suggestion-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

/* Item Suggestion Css End */
/* For row select pagination */
.MuiToolbar-root{
  align-items: baseline !important;
}

/* Notification Css Start*/
.notification-container {
  border: 2px solid #d4e1f5;
  padding: 16px;
  width: 60%;
  border-radius: 8px;
  background: #f8faff;
  margin-top: 1rem;
 
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.notification-header h2 {
  font-size: 18px;
  font-weight: bold;
}

.notification-mark-all {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.notification-list {
  display: flex;
  flex-direction: column;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
}

.notification-icon {
  font-size: 20px;
  margin-right: 12px;
}

.notification-details {
  flex: 1;
}

.notification-details p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.notification-time {
  font-size: 12px;
  color: gray;
  min-width: 40px;
  text-align: right;
}

.notification-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  transition: box-shadow 0.3s ease-in-out;
}

/* Shadow effect */
.notification-status.unread {
  box-shadow: 0px 0px 8px rgba(128, 128, 128, 0.3);
}

.notification-status.read {
  box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.3);
}

/* Hover effects */
.notification-status.unread:hover,
.notification-status.read:hover {
  box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.5);
}

.bell-count {
  position: absolute;
    top: -10px;
    right: -11px;
    border-radius: 50%;
    background-color: #007bff;
    display: inline-grid;
    width: 23px;
    font-size: 12px;
    place-content: center;
    aspect-ratio: 1 / 1;
    color: white;
}

/* Blue dot positioning */
.blue-dot {
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/* Notification Css End */



