.custom-accordion-header {

  background-color: #F3F3F3;

  /* Additional styling if needed */

}/*# sourceMappingURL=deliveryChallan.css.map */
 
 
.material-outward-heading th{

  font-weight: 500;    

  width: 8rem;

  color:#000000;

  font-size: 14px;

  background-color: #FCFCFC;

  border:none;

  border-bottom: 1px solid #BDBDBD;

}

.material-outward-body td {

  color:#000000;

  font-weight: 600;  

  font-size: 14px;   

  border-bottom: 1px solid #BDBDBD;

}

.material-outward-total-amount{

  color: #d90000 !important;;

}
 
.material-outward-heading > tr th:first-child {

  width: 6%;

  padding-right: 0;

}
 
.material-outward-heading > tr th:nth-child(2) {

  width: 11%;

}

.material-outward-heading > tr th:nth-child(3) {

  width: 10%;

}

.material-outward-heading > tr th:nth-child(4) {

  width: 9%;

}

.material-outward-heading > tr th:nth-child(5) {

  width: 14%;

}

.material-outward-heading > tr th:nth-child(6) {

  width: 8%;

}

.material-outward-heading > tr th:nth-child(7) {

  width: 10%;

}

.material-outward-heading > tr th:nth-child(8) {

  width: 10%;

}

.material-outward-heading > tr th:nth-child(9) {

  width: 10%;

}

.material-outward-heading > tr th:nth-child(10) {

  width: 10%;

}
 