.container {
  overflow-x: auto;
  padding: 1rem;
}

.tender-table {
  width: 100%;
  border-collapse: collapse;
}

.tender-table th,
.tender-table td {
  padding: 10px 15px;
  text-align: left;
}

.tender-table th {
  background-color: #EEF3FF;
  color: #8B909A;
  font-size: 14px;
  font-weight: 500;
}

.tender-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}

.status-completed {
  background-color: blue;
}

.status-in-progress {
  background-color: gray;
}

.status-pending {
  background-color: lightgray;
}

.assignee-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.edit-icon {
  font-size: 16px;
  cursor: pointer;
  color: #333;
}

.select-user {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.start-activity {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  background: none;
  border: none;
  font-size: 14px;
}

.start-activity:disabled {
  color: gray;
  cursor: not-allowed;
}
