.table-container-scroll {
  width: 100%;
  overflow: auto;
}
.table-container-scroll .sd-list-table {
  table-layout: fixed;
}
.table-container-scroll .sd-list-table tr {
  border-bottom: 1px solid lightgrey;
  padding: 0;
  margin: 0;
}
.table-container-scroll .sd-list-table p {
  margin: 0;
}
.table-container-scroll .sd-list-table h6 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-container-scroll .sd-list-table td,
.table-container-scroll .sd-list-table th {
  border: 1px solid lightgrey;
  border-top: none;
  min-width: 16rem;
  max-width: 16rem;
}
.table-container-scroll .table thead {
  display: block;
}
.table-container-scroll .table tbody {
  display: block;
  overflow-y: scroll;
  max-height: 600px;
  padding: 0;
}
.table-container-scroll .table tbody::-webkit-scrollbar {
  width: 12px;
}
.table-container-scroll .table tbody::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.table-container-scroll .table tbody::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.backoffice-kanban .kanban-main-card .status-dropdown button {
  padding: 0.25rem 0.5rem !important;
  background-color: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
  color: black !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
  cursor: auto;
}
.backoffice-kanban .kanban-main-card .status-dropdown .dropdown-toggle-split {
  padding: 0.25rem 1rem !important;
  cursor: pointer !important;
}
.backoffice-kanban .kanban-main-card .status-dropdown .dropdown-menu {
  border: 1px solid lightgray;
}
.backoffice-kanban .kanban-main-card .status-dropdown .dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
}
.backoffice-kanban .kanban-main-card .next-stage-btn {
  background-color: white;
  width: 100%;
  text-align: center;
  padding: 0.4rem 0;
  border: 1px solid;
  transition: 0.2s ease-in-out;
}
.backoffice-kanban .kanban-main-card .next-stage-btn:hover {
  background-color: black;
  color: white;
}/*# sourceMappingURL=BackofficeStyle.css.map */