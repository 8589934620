@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


.gap_24 {
  padding: 24px;
  border-radius: 10px;
  border: 1px 0px 0px 0px;
  // opacity: 0px;
  background-color: #ffffff;
}

.finance10PX {
  padding: 10px;
}

.total_employee {
  border-radius: 16px;
  // opacity: 0px;
  background-color: #f9f2ff;
  padding: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #000000;
  line-height: 24px;
}

.acordianfix_d .css-13xfq8m-MuiTabPanel-root {
  padding: 0 !important;
  padding-top: 24px !important;
}

.acordianfix_d .css-19kzrtu {
  padding: 0 !important;
  padding-top: 24px !important;
}

.justifyContentSpace {
  justify-content: space-between;
}

.marginBottom24px {
  margin-bottom: 24px;
  gap: 20px;
  display: flex;
}

.payslipButtonStyle {
  width: 98.52px;
  height: 30.96px;
  top: 574.02px;
  left: 340px;
  gap: 0px;
  border-radius: 6px;
  // opacity: 0px;
  background: #ffffff;
  border: 0.94px solid #e1e1e1;
  color: #000000;
}

.salaryButtonStyle {
  width: 98.52px;
  height: 30.96px;
  top: 574.02px;
  left: 340px;
  gap: 0px;
  border-radius: 6px;
  // opacity: 0px;
  background-color: #d03438;
  color: #ffffff;
  border: none;
}

.salaryButtonStyles {
  width: 183px;
  height: 30.96px;
  top: 574.02px;
  left: 340px;
  gap: 0px;
  border-radius: 6px;
  // opacity: 0px;
  background-color: #d03438;
  color: #ffffff;
  border: none;
}

.marginLeft {
  margin-left: 5px;
}

.marginRight {
  margin-right: 5px;
}

.paymentMode {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
  text-align: left;
  color: #6a6a6a;
}

.mAutoDisplayFlex {
  margin: auto;
  display: flex;
}

.reviseSalary {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #d90000;
  padding: 24px;
  padding-bottom: 0;
}

.displayGrid {
  display: grid;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 30px;
}

.currentSalaryValue {
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  text-align: left !important;
  color: #000000 !important;
}

.currentSalaryHeading {
  margin-bottom: 10px !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 15px !important;
  text-align: left !important;
  color: #6a6a6a !important;
}

.topLeftRight24px {
  padding: 24px;
  padding-bottom: 0;
}

.justifyContentSpace {
  justify-content: space-between;
  display: flex;
}

.textOfpARRA {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 15.78px;
  text-align: left;
  color: #969696;
  margin-top: 10px;
}

.bordeRadius10px {
  border-radius: 10px;
}

.cardHeight680PX {
  height: 680px;
}

.datEPayslipStyle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #4667b6;
}

.dateParra {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  color: #606060;
}

.borderNone_1px {
  border: none !important;
  border-bottom: 1px solid #e1e1e1;
  padding: 20px;
  border-radius: 10px;
  color: #4667b6;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 28.7px;
  text-align: left;
}

.cardHeight680PX {
  height: 600px;
  padding: 24px;
}

.cardHeight680PXH {
  height: 600px;
  background-color: #f2f2f2;
  margin-top: 27px;
}

.datEPayslipStyle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #4667b6;
}

.dateParra {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  color: #606060;
}

.bordeRadius10px .css-1869usk-MuiFormControl-root {
  margin: 0px !important;
  height: 69px !important;
}

.marginBottom20px {
  margin-bottom: 60px;
}

.marginAuto {
  margin: auto;
  margin-bottom: 47px;
  margin-top: 47px;
}

.bankTransfer {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #000000;
}

.padding_24px {
  padding-left: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.absolute_fix {
  margin-top: 20px;
}

.table_24 {
  padding-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 0px;
}

.lastMonth {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #777777;
}

.assignedAssets {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 28.7px;
  text-align: left;
  color: #4667b6;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.paddingLeftRight0 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.boxShadowNone {
  box-shadow: none !important;
  border: none !important;
}

.borderBottomOpx {
  border-bottom: none !important;
}

.headingStyle {
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 15px !important;
  text-align: left !important;
  color: #797979 !important;
}

.paddingLeftRight0 .employee-Accordian {
  background-color: inherit !important;
  border-bottom: 1px solid #bdbdbd;
}

.border0px {
  border: 0px !important;
}

.cellStyle {
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  text-align: left !important;
  color: #000000 !important;
}

.dateFormate {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #838383;
}

.textOfpARRAf {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 15.78px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
}

.textOfpARRAs {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.78px;
  text-align: center;
  color: #969696;
}

.buttonFeedF {
  width: 132px;
  height: 36px;
  top: 847px;
  left: 669px;
  gap: 0px;
  border-radius: 5px;
  border: 1px solid #ef4d56;
  // opacity: 0px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  text-align: center;
  color: #d90000;
}

.justifySpa {
  gap: 17px;
  display: flex;
}

.ml-2m {
  margin-left: 10px;
}

.mr-2m {
  margin-right: 10px;
}

.buttonFeedT {
  width: 181px;
  height: 36px;
  top: 847px;
  left: 818px;
  gap: 0px;
  border-radius: 5px;
  // opacity: 0px;
  background-color: #d03438;
  color: #ffffff;
  border: none;
}

.registered {
  border-radius: 16px;
  // opacity: 0px;
  background-color: #fff9ed;
  padding: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #000000;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 24px;
}

.not_invited {
  padding: 10px;
  border-radius: 16px;
  // opacity: 0px;
  background-color: #ffedfa;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.employee_count {
  margin: auto;
  display: flex;
  border-radius: 10px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  // opacity: 0px;
  background-color: #ffffff;
  justify-content: space-between;
  margin-top: 24px;
}

.emp_fontStyle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 13.13px;
  text-align: left;
  color: linear-gradient(0deg, #000000, #000000), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.fourty {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #151d48;
}

.dashboard {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #000000cc;
}

.margin_top {
  margin-top: 24px;
}

.button_quickLink {
  width: 285px;
  height: 47px;
  top: 565px;
  left: 678px;
  gap: 0px;
  border-radius: 8px;
  // opacity: 0px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.edit_primary_details {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 14.77px;
  text-align: left;
  color: #4667b6;
}

.button_quickLink:hover {
  width: 285px;
  height: 47px;
  top: 565px;
  left: 678px;
  gap: 0px;
  border-radius: 8px;
  // opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffefef;
  color: #d90000;
  border: 1px solid #d90000;
  cursor: pointer;
}

.d_flex_space {
  display: flex;
  justify-content: space-between;
}

.days_selector_style {
  border-radius: 10px;
  margin-top: 16px;
  margin-right: 16px;
  padding: 0.5rem 7rem 0.5rem 1rem;
  border: 1px solid #6a6a6a;
  outline: none;
  box-shadow: none;
}

.emp_login_fontStyle {
  padding-left: 1rem;
  padding-top: 1rem;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 13.13px;
  text-align: left;
  color: linear-gradient(0deg, #000000, #000000), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.abbreviation_circle {
  width: 50px;
  height: 50px;
  background-color: violet;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  color: #f9f2ff;
  font-family: Poppins;
  font-size: 19.5px;
  font-weight: 600;
  margin-left: 16px;
  margin-top: 10px;
}

.emp-name {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.emp {

  max-width: 495px;
  min-height: 308px;
  max-height: 308px;

  &:hover {
    border: 1px solid #d90000 !important;
  }
}

.job_title {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 12.56px;

  margin-left: 0.5rem;
}

.date {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 12.56px;
  text-align: left;
}



.ml_4_left_right {
  margin-left: 24px;
  margin-right: 24px;
}

.accordian-card_fix {
  height: 337px;
}

.card_value {
  /* margin-left: 4rem; */
  // padding-left: 2rem;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
}

.card_label {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;

  color: #8b909a;
}

.designation {
  font-size: 12px;
  font-family: Poppins;
  display: inline-block;
  font-weight: 500px;
  border-radius: 4px;
  border: solid #4667b6 1px;
  padding: 1px 10px 1px 10px;
  color: #4667b6;
  background-color: #e3e6ee;
}

.add_emp_count {
  line-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  color: #4667b6;
  display: inline-block;

  background-color: white;
  border: 2px solid #4667b6;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  // background-color: #4667b6;
  // margin:10px;
}

.add_emp {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 400;
  color: #000000;

  text-align: left;

}



.add-emp-accordian {
  svg {
    height: 35px !important;
  }

  .card-header {
    padding: 0;
    padding-top: 1rem;
    border: none;
    border-top: 1px solid #eeeeee;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .accordion-collapse {
    border-bottom: 1px solid #eeeeee;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .add_emp_non_active {
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    padding-left: 12px;
    text-align: left;

  }

  .add_emp_active {
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 600;
    color: #4667B6;
    text-align: left;
    padding-left: 12px;

  }



}

.add_emp_input input {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid black;
  padding-left: ".75rem";
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;

  color: #000000;
}

.add_emp_input_active input {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid red !important;
  padding-left: ".75rem";
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
}

.add_emp_input select {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid black;
  padding-left: ".75rem";
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;

  color: #000000;
}

.add_emp_input_active select {
  border: none;
  border-bottom: 1px solid red !important;
  padding-left: ".75rem";
  border-radius: 0px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
}

.payroll_setting {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;

  text-align: left;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.checkbox-label {
  width: 215px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;

  color: #838383;
}

.active-checkbox {
  width: 215px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;

  color: red;
}

.add-new-employee {
  .activeDiv {
    background-color: #f2f6ff;

    .add_emp_count {
      color: white;
      background-color: #4667B6;
    }

    .add_emp {
      color: #4667b6;
    }
  }
}


.vertical-line {
  border-left: 1px solid #dcdcdc;
  /* Adjust thickness and color as needed */
  height: 100px;
  /* Adjust height as needed */
  margin-left: 79px;
  padding: 11rem 0rem 1rem 0rem;
  margin-top: 23px;
}

.padding_leftto20px {
  padding-left: 20px;
}

.emp_detail {
  border-radius: 10px;

  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }

  .heading {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
  }

  .status {
    border-radius: 5px;
    background-color: #399600;
    color: white;
    padding: 0.1rem 1rem 0.1rem 1rem;
    font-family: Poppins;
    font-size: 14.79px;
    font-weight: 500;

    text-align: center;
  }

  .detail-profile-image {
    position: relative;

    &::before {
      content: "";

      position: absolute;
      z-index: 1;
      background: url("../Assets/editIcon.png") no-repeat;
      width: 24px;
      height: 45px;
      top: 4px;
      right: 0;
    }
  }

  .detail_text {
    padding-top: 0.5rem;
    padding-left: 0.4rem;
  }
}

.css-1ujnqem-MuiTabs-root .employee-Accordian {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.card-right-heading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;

  text-align: left;
}

.card-right-value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: black;
  text-align: left;
}

.detail-card-heading {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;

  text-align: left;
  color: #4667b6;
}

.ongoing-Project {
  border: 1px solid #dfdfdf;
  border-radius: 5px;

  .project-name {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #4667b6;
    text-align: left;
  }

  .date {
    display: inline-block;
    border: 1px solid red;
    color: red;
    border-radius: 5px;
    background-color: #fff3f3;
    font-family: Poppins;
    font-size: 10.79px;
    font-weight: 500;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  }

  .project-content {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
  }

  .stage {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    text-align: left;
    color: #989898;
  }

  .inspection {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;

    color: #000000;
  }
}

.ongoing-heading {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #4667b6;
}

.abbreviation {
  width: 36px;
  height: 35px;
  background-color: violet;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 37px;
  color: #f9f2ff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  // margin-left: 16px;
}

.main-ongoing {
  margin-right: 1rem;
  padding-bottom: 0rem;
}

.main-experience {
  margin-right: 1rem;
}

.order-track {
  &-step {
    display: flex;
    height: 5rem;

    &:last-child {
      overflow: hidden;
      height: 4rem;

      & .order-track-status span:last-of-type {
        display: none;
      }
    }
  }

  &-status {
    margin-right: 1.5rem;
    position: relative;

    &-dot {
      display: block;
      margin-top: 1.5rem;
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 50%;
      background: #4667b6;
    }

    &-line {
      display: block;
      margin: 0 auto;
      width: 2px;
      height: 9rem;
      background: whitesmoke;
    }
  }

  &-text {
    &-stat {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;

      margin-bottom: 3px;
    }

    &-sub {
      font-family: Poppins;
      font-size: 13px;
      font-weight: 300;
    }
  }
}

.order-track {
  transition: all 0.3s height 0.3s;
  transform-origin: top center;
}

.accordian-data {
  max-width: 532px;
  min-width: 532px;
  max-height: 313px;
  min-height: 313px;
}

.accordian_abbreviation_circle {
  width: 44px;
  height: 44px;
  background-color: violet;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  color: #f9f2ff;
  font-family: Poppins;
  font-size: 16.5px;
  font-weight: 600;
  margin-left: -14px;
  margin-top: 10px;
}

.headingStylePayslip {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: #4667b6;
}

.custom-tablist .Mui-selected {
  color: red !important;
  padding-left: 1rem;

  /* Change the color of the selected tab to blue */
}

.custom-tablist .css-1aquho2-MuiTabs-indicator {
  background-color: red !important;
}

.css-heg063-MuiTabs-flexContainer {
  justify-content: space-evenly;
}

.paddingLeftRight0 .css-heg063-MuiTabs-flexContainer {
  justify-content: inherit !important;
}

.horizontal-row {
  border-bottom: 1px solid #e1e1e1;
}

.document-type {
  h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    padding-left: 22px;
    color: #4667b6;
  }

  .document-active {
    background-color: #f2f6ff;
    // margin: 0.5625rem;
    // margin-right: 0.625rem;
  }

  .documentType-icon {
    background-color: #4667b6;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }

  .heading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    padding-left: -1px;
    padding-top: 0.2rem;
  }

  .heading-value {
    font-family: Poppins;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    padding-top: -1rem;
  }

  .img-div {
    // padding-left: 1rem;
    padding-top: 0.9rem;
    padding-right: 0.5rem;
  }
}

.document-upload {

  // padding-left: 22px;
  .upload-degree {
    border: 3px dotted #4667b6;
    background-color: #f2f6ff;
    border-radius: 8px;
    padding: 10px 10px 10px 10px;

    button {
      color: "white";
      background-color: #d03438;
    }
  }

  h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    // padding-left: 22px;
    color: #4667b6;
  }

  .detail-section {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    color: #606060;
    // padding-left: 22px;
  }

  .document-detail-heading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;

    text-align: left;
  }

  .document-detail-value {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;

    text-align: left;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .checkbox-label {
    width: 215px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;

    color: #838383;
  }

  .active-checkbox {
    width: 215px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;

    color: red;
  }



  .vertical-line {
    border-left: 1px solid #dcdcdc;
    /* Adjust thickness and color as needed */
    height: 100px;
    /* Adjust height as needed */
    margin-left: 79px;
    padding: 11rem 0rem 1rem 0rem;
    margin-top: 23px;
  }

  .emp_detail {
    border-radius: 10px;

    img {
      border-radius: 10px;
      min-width: 152px;
      max-width: 152px;
      min-height: 152px;
      max-height: 152px;
      // padding: 1rem;
    }

    .heading {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
    }

    .status {
      border-radius: 5px;
      background-color: #399600;
      color: white;
      padding: 0.1rem 1rem 0.1rem 1rem;
      font-family: Poppins;
      font-size: 14.79px;
      font-weight: 500;

      text-align: center;
    }

    // .card-right-heading {
    //     font-family: Poppins;
    //     font-size: 12px;
    //     font-weight: 400;
    //     color:#000000;
    //     // text-align: left;

    // }

    // .card-right-value {
    //     font-family: Poppins;
    //     font-size: 14px;
    //     font-weight: 400;
    //     color:#000000;
    //     text-align: left;
    //     padding-left: 1.5rem;

    // }

    .detail-profile-image {
      position: relative;

      &::before {
        content: "";

        position: absolute;
        z-index: 1;
        background: url("../Assets/editIcon.png") no-repeat;
        width: 45px;
        height: 45px;
        left: 129px;
        top: 4px;
        right: 0px;
      }
    }

    .detail_text {
      padding-top: 0.5rem;
      padding-left: 0.4rem;
    }
  }

  // .css-1ujnqem-MuiTabs-root .employee-Accordian {
  //     margin-left: 5rem !important;
  //     margin-right: 5rem !important;
  // }
  .accordian .css-13xfq8m-MuiTabPanel-root {
    padding: 0px !important;
  }

  .card-right-heading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;

    text-align: left;
  }

  .card-right-value {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: black;
    text-align: left;
  }

  .detail-card-heading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;

    text-align: left;
    color: #4667b6;
  }

  .ongoing-Project {
    border: 1px solid #dfdfdf;
    border-radius: 5px;

    .project-name {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      color: #4667b6;
      text-align: left;
    }

    .date {
      display: inline-block;
      border: 1px solid red;
      color: red;
      border-radius: 5px;
      background-color: #fff3f3;
      font-family: Poppins;
      font-size: 10.79px;
      font-weight: 500;
      padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    }

    .project-content {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
    }

    .stage {
      font-family: Poppins;
      font-size: 10px;
      font-weight: 500;
      line-height: 10px;
      text-align: left;
      color: #989898;
    }

    .inspection {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;

      color: #000000;
    }
  }

  .ongoing-heading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    color: #4667b6;
  }

  .abbreviation {
    width: 36px;
    height: 35px;
    background-color: violet;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 37px;
    color: #f9f2ff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    // margin-left: 16px;
  }

  .main-ongoing {
    margin-right: 1rem;
    padding-bottom: 0rem;
  }

  .main-experience {
    margin-right: 1rem;
  }

  .order-track {
    &-step {
      display: flex;
      height: 5rem;

      &:last-child {
        overflow: hidden;
        height: 4rem;

        & .order-track-status span:last-of-type {
          display: none;
        }
      }
    }

    &-status {
      margin-right: 1.5rem;
      position: relative;

      &-dot {
        display: block;
        margin-top: 1.5rem;
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 50%;
        background: #4667b6;
      }

      &-line {
        display: block;
        margin: 0 auto;
        width: 2px;
        height: 9rem;
        background: whitesmoke;
      }
    }

    &-text {
      &-stat {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;

        margin-bottom: 3px;
      }

      &-sub {
        font-family: Poppins;
        font-size: 13px;
        font-weight: 300;
      }
    }
  }

  .order-track {
    transition: all 0.3s height 0.3s;
    transform-origin: top center;
  }

  .accordian-data {
    max-width: 532px;
    min-width: 532px;
    max-height: 313px;
    min-height: 313px;
  }

  .accordian_abbreviation_circle {
    width: 44px;
    height: 44px;
    background-color: violet;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 48px;
    color: #f9f2ff;
    font-family: Poppins;
    font-size: 16.5px;
    font-weight: 600;
    margin-left: -14px;
    margin-top: 10px;
  }

  .custom-tablist .Mui-selected {
    color: red !important;
    padding-left: 1rem;

    /* Change the color of the selected tab to blue */
  }

  .custom-tablist .css-1aquho2-MuiTabs-indicator {
    background-color: red !important;
  }

  .css-heg063-MuiTabs-flexContainer {
    justify-content: space-evenly;
  }

  .horizontal-row {
    border-bottom: 1px solid #e1e1e1;
  }

  .document-type {
    h4 {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      padding-left: 22px;
      color: #4667b6;
    }

    .document-active {
      background-color: #f2f6ff;
      margin: 0.5625rem;
      margin-right: 0.625rem;
    }

    .documentType-icon {
      background-color: #4667b6;
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }

    .heading {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      padding-left: -1px;
      padding-top: 0.2rem;
    }

    .heading-value {
      font-family: Poppins;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      padding-top: -1rem;
    }

    .img-div {
      padding-left: 1rem;
      padding-top: 0.9rem;
      padding-right: 0.5rem;
    }
  }

  .document-upload {

    // padding-left: 22px;
    .upload-degree {
      border: 3px dotted #4667b6;
      background-color: #f2f6ff;
      border-radius: 8px;
      padding: 10px 10px 10px 10px;

      button {
        color: "white";
        background-color: #d03438;
      }
    }

    h4 {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      // padding-left: 22px;
      color: #4667b6;
    }

    .detail-section {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 300;
      color: #606060;
      // padding-left: 22px;
    }

    .document-detail-heading {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 300;

      text-align: left;
    }

    .document-detail-value {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;

      text-align: left;
    }
  }
}

.upload-file-modal {
  border: 1px dotted #4667b6;
  border-radius: 5px;
  background-color: #f2f6ff;
}

.img-prev {
  max-width: 100px;
  min-width: 100px;
  min-height: 152px;
  max-height: 152px;
}

.document-modal .modal-content .modal-header .modal-title {
  font-family: Poppins !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #4667b6 !important;
  text-align: left !important;
}

.attendance-stats {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #4667b6;
  text-align: left;
}

.emp-name-attendance {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  text-align: left;
}

.attendance-stats-heading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  color: #6a6a6a;
  text-align: left;
}

.attendance-stats-value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.attendance-table-head {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #6a6a6a;
}

.attendance-table-body {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

//responsive

@media (min-width: 800px) and (max-width: 1421px) {
  .add_emp_non_active {
    font-size: 10px;
    padding-left: 10px;
  }

  .add_emp_active {
    font-size: 10px;
    padding-left: 10px;
  }

  .activeDiv {
    width: 93%;
    height: 65px;
    margin: 12px 8px 12px 8px;
  }

  .add_emp_count {
    width: 35px;
    height: 35px;
  }
}

.border_None {
  border: none;
}

.curserPointer {
  cursor: pointer;
}

.hr-header .navbar-custom {
  background-color: #D8E0F0;
}

.hr-header .list-unstyled {
  padding-left: 2.625rem !important;
}

.hr-header .search-Button {
  font-size: 21px;
  // border-bottom-right-radius: 20px;
  // border-top-right-radius: 20px;
  position: absolute;
  left: 0px;
  top: 0px;
  color: #C2C2C2;
  // background-color: red;
  width: 41px;
  height: 37px;
  padding-left: 9px;
}

// .dropdown-menu{
//   min-width: 0;
// } 

.initiate-Exit-Color {
  color: #616161
}

.initiate-Exit-font {
  font-size: .93rem;
}

.exit-modal {.form-check-input:checked {
  background-color: #2c2929 !important;
  border-color: #2c2929;
}
.form-check-input{
 border:1px solid #616161
}}
.custom-icon-attachment {
  color: red !important; /* Force the color */
}
.exit-modal .checkbox-hire .form-check-input:checked {
  background-color:
    #4667B6 !important;
  border-color:
    #4667B6;
}

@media (max-width: 1421px) {
  .days_selector_style {
    padding: 0.3rem 1rem 0.3rem 1rem;
  }

  .employees-directory .dashboard {
    font-size: 20px !important;
  }

  .employee-directory.py-lg-3 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .employee-directory.px-lg-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .employee-directory.px-lg-4 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .employee-directory .emp-name {
    font-size: 12px;
  }

  .employee-directory.ps-5 {
    padding-left: 11px !important;
  }

  .employee-directory .card_label {
    font-size: 11px !important;
  }

  .employee-directory .card_value {
    font-size: 11px !important;
  }
}

.red-button {
  background-color: #0F172A !important;
  border-color: #0F172A !important;
  color: white;


  font-size: 12px;
  font-weight: 400;
}


.white-button {
  background-color: white !important;
  color: #000000;
  border: 1px solid #8f8f8f;

  font-size: 12px;
  font-weight: 400;
}

.employee-main-tab {
  .css-heg063-MuiTabs-flexContainer {}

  .custom-tablist .Mui-selected {
    color: red !important;
    padding-left: 1rem;
  }
}

.aside-list {
  min-height: 500px;
}

.tab-content {
  .main-row {
    background-color: #0f172a;
    border-top-left-radius: 1.1875rem;
    border-bottom-left-radius: 1.1875rem;
    border-top-right-radius: 1.6825rem;
    border-bottom-right-radius: 1.6825rem;
    // border-radius: 1.1875rem;
  }
}

.tab-card {
  border-top-left-radius: 1.5625rem;
  border-bottom-left-radius: 1.5625rem;
  border-top-right-radius: 1.5625rem;
  border-bottom-right-radius: 1.5625rem;

  .tab-card-heading {
    color: #4667b6;
    font-family: Poppins;
  }
}

.inProbation {
  .table-head {
    .css-y8ay40-MuiTableCell-root {
      color: #4667b6 !important;
      background-color: #f4f8ff !important;
    }
  }
}

.probation-active-class {
  background-color: #0F172A;
  color: white;
}

.hr.btn-primary {
  background-color: #0F172A !important;
  border-color: #0F172A !important;
  ;
}

.hr.btn-primary:hover {
  background-color: #0F172A !important;
  border-color: #0F172A !important;
}

.tab-card-heading {
  color: #4667b6;
}

.job-Work-Time-CommonTable {
  .table-head {
    .css-y8ay40-MuiTableCell-root {
      color: #4667b6 !important;
      background-color: #f4f8ff !important;

    }
  }
}

.exits-table {
  .table-head {
    .css-y8ay40-MuiTableCell-root {
      color: #4667b6 !important;
      background-color: #f4f8ff !important;

    }
  }
}

.organisation_Structure_Tab {
  .css-1tzn5uo-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: red !important;
    padding-left: 1rem;
  }

  .css-1aquho2-MuiTabs-indicator {
    background-color: red !important;
  }

  .tab-card-heading {
    color: #4667b6;
  }
}