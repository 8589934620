/* Material Tracker Container */
.material-tracker-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5625rem; /* 25px */
  font-family: "Poppins", sans-serif;
  flex-wrap: wrap;
}

/* Title */
.material-tracker-title {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem; /* 24px */
  font-weight: 600;
  color: #0F172A;
}

/* Controls Wrapper */
.material-tracker-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 0.9375rem; /* 15px */
  align-items: flex-end;
  padding-right: 0.75rem; /* 1.2rem remains unchanged */
}

/* Dropdown */
.material-tracker-dropdown {
  width: 9.375rem; /* 150px */
  padding: 0.5rem; /* 8px */
  border: none;
  border-radius: 0.25rem; /* 4px */
  font-size: 0.875rem; /* 14px */
  color: #9198A4;
  background-color: #F0F4FF;
}

/* Search Bar */
.material-tracker-search {
  width: 12.5rem; /* 200px */
  padding: 0.5rem; /* 8px */
  margin-left: -0.625rem; /* -10px */
  border: none;
  border-radius: 0.25rem; /* 4px */
  font-size: 0.875rem; /* 14px */
  color: #9198A4;
  background-color: #FFFFFF;
  margin-right: 1rem;
}

/* Date Picker */
.material-tracker-calendar {
  width: 8.125rem; /* 130px */
  padding: 0.5rem; /* 8px */
  border: none;
  border-radius: 0.25rem; /* 4px */
  font-size: 0.875rem; /* 14px */
  color: #0F172A;
  background-color: #fff;
}

/* Download Button */
.material-tracker-button {
  background-color: #D03438;
  color: #fff;
  border: none;
  padding: 0.625rem 2.5rem; /* 10px 40px */
  border-radius: 0.3125rem; /* 5px */
  font-size: 0.875rem; /* 14px */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.material-tracker-button:hover {
  background-color: #b52d30;
}

.material-card {
  border-radius: 1.25rem; /* 20px */
  margin-top: 0;
  background-color: white;
  padding: 1rem;
  height: 78vh; /* Fixed height */
  overflow: hidden; /* Prevent scrolling on the card */
  position: relative; /* Ensures sticky elements work */
}

.material-tracker-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  text-align: left;
  display: block; /* Enables scrolling inside */
  height: calc(100% - 2rem); /* Adjust height for padding */
  overflow-y: auto; /* Enable scrolling for the table */
}

.material-tracker-table th {
  background-color: #f4f4fb;
  color: #8b909a;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.625rem 0.3125rem; /* 10px 5px */
  text-align: left;
  border: none;
  border-radius: 0.375rem; /* 6px */
  position: sticky; /* Makes the header sticky */
  top: 0; /* Sticks to the top of the container */
  z-index: 1;
}

/* Width Adjustments for Table Headers */
.material-heading > tr th:first-child {
  width: 5rem;
  padding-right: 0;
}

.material-heading > tr th:nth-child(2) {
  width: 20rem;
}

.material-heading > tr th:nth-child(3) {
  width: 20rem;
}

.material-heading > tr th:nth-child(4) {
  width: 10rem;
}

.material-heading > tr th:nth-child(5) {
  width: 14rem;
}

.material-heading > tr th:nth-child(6) {
  width: 14rem;
}

.material-heading > tr th:nth-child(7) {
  width: 14rem;
}

.material-heading > tr th:nth-child(8) {
  width: 10rem;
}

.material-heading > tr th:nth-child(9) {
  width: 14rem;
}

.material-tracker-table td {
  color: #000000;
  font-size: 0.9rem;
  padding: 0.6875rem; /* 11px */
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
  padding-left: 0.3125rem; /* 5px */
}

.material-tracker-subtable-heading th {
  background-color: #F0F0F0;
  color: #626262;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #DADADA !important;
  border-radius: 5px;
  font-size: 0.9rem;
}
.material-tracker-subtable-heading td {
  color: #000000;
}

.material-tracker-subtable-heading > tr th:first-child {
  width: 8rem;
  padding-right: 0;
}

.material-tracker-subtable-heading > tr th:nth-child(2) {
  width: 10rem;
}

.material-tracker-subtable-heading > tr th:nth-child(3) {
  width: 10rem;
}

.material-tracker-subtable-heading > tr th:nth-child(4) {
  width: 10rem;
}

.material-tracker-subtable-heading > tr th:nth-child(5) {
  width: 14rem;
}

.material-tracker-subtable-heading > tr th:nth-child(6) {
  width: 14rem;
}

.material-tracker-subtable-heading > tr th:nth-child(7) {
  width: 14rem;
}/*# sourceMappingURL=Material.css.map */