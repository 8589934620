.table-container-scroll {
    width: 100%;
    overflow: auto;

    .project-list-table {
        table-layout: fixed;
        tr {
            border-bottom: 1px solid lightgrey;
            padding: 0;
            margin: 0;
        }

        p {
            min-width: 13rem;
            max-width: 13rem;
            margin: 0;
        }
        h6 {
            font-size: 1.1rem;
            margin: 0.5rem 0;
            font-weight: 600;
            min-width: 13rem;
            max-width: 13rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        td,
        th {
            border: 1px solid lightgrey;
            border-top: none;
        }
    }

    .material-list-table {
        table-layout: fixed;
        p {
            min-width: 13rem;
            max-width: 13rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
        }
        h6 {
            font-size: 1.1rem;
            margin: 0.5rem 0;
            font-weight: 600;
            min-width: 13rem;
            max-width: 13rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .table thead {
        display: block;
    }

    .table tbody {
        display: block;
        overflow-y: scroll;
        max-height: 600px;
        padding: 0;
    }

    .table tbody::-webkit-scrollbar {
        width: 12px;
    }

    .table tbody::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
    }

    .table tbody::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }
}
