$red: #d03438;

.cursor-pointer{
    cursor: pointer;
}

.page-wrapper {
    position: relative;

    .pfp {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        backdrop-filter: blur(4px);
        height: 100%;
        z-index: 1;
        bottom: 4rem;

        img {
            height: 15rem;
            border-radius: 50%;
        }
    }
}

.department__card {
    box-shadow:
        rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 1rem;
    border-radius: 0.6rem;
    transition: 0.2s ease-in-out;

    &:hover {
        box-shadow:
            rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }

    .depart_info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            font-size: 1.4rem;
            cursor: pointer;
        }
    }

    .department_description {
        h6 {
            min-height: 4rem;
            max-height: 4rem;
        }
    }

    .multiple_user {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .img__ {
            img {
                margin-left: -1rem;
            }

            p {
                background-color: rgba(253, 60, 151, 0.15) !important;
                color: #fd3c97;
                padding: 0.5rem 0.6rem;
                margin-bottom: 0;
                border-radius: 50%;
                font-weight: 500;
                font-size: 1.1rem;
            }
        }
    }

    .multiple_user_1 {
        display: flex;
        align-items: center;

        .titl__ {
            font-weight: 600;
        }

        a {
            font-size: 1rem;
            font-weight: 500;
            color: #d03438;
        }

        .link-arrow span {
            display: inline-block;
            transition: 0.1s ease-in;
        }

        .link-arrow:hover span {
            transform: translateX(50%);
        }
    }
}

.department__card_detail {
    padding: 1rem;
    border-radius: 0.6rem;

    ul {
        background: none !important;

        .nav-link {
            padding: 0.5rem 3rem;
            border: 1px solid $red;
            margin-left: 0.6rem;
            color: $red;
        }
    }
}

.add-button,
.remove-button {
    background-color: transparent;
    border: 0;
    font-size: 2rem;
    font-weight: 500;
    transition: 0.2s ease-in-out;

    &:hover {
        color: $red;
    }
}

.remove-button-disabled {
    background-color: transparent;
    border: 0;
    opacity: 0.5;
    font-size: 2rem;
    font-weight: 500;
    color: gray;
    cursor: not-allowed;
}

.cursor-pointer {
    cursor: pointer;
}

.department-form {
    input,
    textarea {
        border: 1px solid lightgray;
    }
}

.detail-table {
    th,
    td {
        font-size: 12px;
    }
}

.clear {
    .profile-pic {
        position: relative;
        display: inline-block;

        .overlay_1 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: 2s ease;

            &:hover {
                display: block;
                background: rgba(0, 0, 0, 0.3);
            }
        }
    }

    .profile-pic:hover .edit {
        display: block;
    }

    .edit {
        padding-top: 7px;
        padding-right: 7px;
        position: absolute;
        left: 45%;
        top: 37%;
        display: none;
        transition: 2s ease;
        font-size: 1.5rem;
    }

    .edit a {
        color: white;
        background: rgba(0, 0, 0, 0.77);
        padding: 0.5rem;
        border-radius: 0.5rem;
    }
}

.btn-department__ {
    button {
        padding: 0.4rem 1rem;
        background-color: #d03438;
        border: 1px solid #d03438;
        color: white;
        margin-left: 0.5rem;
    }
}

.department_description {
    p {
        margin: 0;
    }
}

.recharts-surface {
    max-width: 100%;
    width: 100%;
}

.maximum-panding {
    // background-color: rgb(207, 243, 241);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    padding-bottom: 2.8rem;
    h4 {
        font-size: 1.1rem;
        color: #d03438;
        font-weight: 600;
    }
}

.project__img {
    img {
        max-width: 20rem;
        max-height: 10rem;
    }
}

.kanban-board .kanban-col {
    .kanban-main-card {
        background: #eef0f2;
    }
}

.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
    z-index: 0;
}

.menu-content {
    .inactive-link {
        color: #9da2af;
        &:hover {
            color: white !important;
        }
    }

    .active {
        color: white !important;
        font-weight: bold;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.table-border-radius {
    border-radius: 0.7rem;
    overflow: hidden;
    .accordion-item {
        .accordion-header {
            .accordion-button {
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
        .accordion-collapse {
            .accordion-body {
                .table {
                    margin-bottom: 0;
                }
            }
        }
    }
    .accordion-button:not(.collapsed) {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
        background-color: transparent;
    }
    .table-responsive {
        .table {
            margin-bottom: 0;
        }
    }

    table tr td p {
        color: #3a3a3a;
        font-style: normal;
    }
}

.card-radius {
    border-radius: 1.2rem;
    // border: 1px solid #0f172a;
}

.bg-table-black {
    background-color: #0f172a;
}

.dashboard-text {
    color: #3a3a3a;
    font-style: normal;
    // line-height: normal;
}

/* Add this CSS to your stylesheet or inline styles */
.dashboard-table table {
    table-layout: fixed;
}

.dashboard-table th,
.dashboard-table td {
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.dashboard-table th,
.dashboard-table td {
    width: 9.5rem; /* 100% / 16 columns */
    padding: 0.8rem;
    text-align: center;
}

.border .th-min-long,
.td-min-long {
    width: 18rem;
    padding: 0.8rem;
    text-align: center;
}

.border .th-long,
.td-long {
    width: 25rem;
    padding: 0.8rem;
    text-align: center;
}
// .border .th-small-mid,
// .td-small-mid {
//     width: 5rem;
//     padding: .8rem;
//     text-align: center;
// }

.border .th-small,
.td-small {
    width: 6rem;
    padding: 0.8rem;
    text-align: center;
}

.border .th-mid,
.td-mid {
    width: 9rem;
}
.nav-item:has(.inactive-link.active) {
    border-radius: var(--8, 8px);
    background: var(--white-100, #fff);
    a {
        color: #d03438 !important;
    }
}

///// Material Inspection Table

.material-inspection-table {
    h5 {
        width: 13rem;
        font-weight: 500;
        font-size: 1rem;
    }
}
.tender-list-table {
    p {
        min-width: 9rem;
        max-width: max-content;
        margin: 0;
    }
    h6 {
        font-size: 1.1rem;
        margin: .5rem 0;
        font-weight: 600;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

////// EDIT ADD PROJECT /////
.accordianHeader {
    .accordion-button {
        font-size: 1rem;
        font-weight: 600;
    }
}

//// PROJECT CARD /////
.project-card {
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
        box-shadow:
            rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
}
.categorty-name {
    border: 1px solid gray;
    display: grid;
    place-content: center;
    height: 3rem;
    width: 3rem;
}

///// USERS CARD /////////

.user-card {
    transition: 0.2s ease-in-out;
    border: 1px solid lightgray;
    &:hover {
        box-shadow:
            rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
}


.base-Popper-root{
    width: 30% !important;
}