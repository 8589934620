.width_180px {
  width: 180px;
  height: 127px;
}
.margin_l_5 {
  margin-left: 5px;
  margin-right: 5px;
}
.days_selector_style_leave {
  border-radius: 10px;
  border: 1px solid #6a6a6a;
  outline: none;
  box-shadow: none;
  padding-left: 5px;
  padding-right: 5px;
  width: 148px;
}
.emp_login_fontStyle_leave {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 13.13px;
  text-align: left;
  color: linear-gradient(0deg, #4667b6, #4667b6),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}
.requestHeading {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 13.13px;
  text-align: left;
  color: linear-gradient(0deg, #4667b6, #4667b6),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  margin-bottom: 30px !important;
}
.requestHeadings {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 13.13px;
  text-align: left;
  color: linear-gradient(0deg, #4667b6, #4667b6),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}
.height_227px {
  height: 227px;
}
.w_160px {
  width: 160px;
}
.marginLeft_10px {
  margin-left: 10px;
}
.marginLRight_10px {
  margin-right: 10px;
}
.leaveProgress {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #272525;
}
.borderLeft_10 {
  border-left: 1px solid #c2c2c2;
}
.pl-2Fix {
  padding-left: 10px;
}
.leaveHeading {
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: left !important;
}

.chartButton {
  width: 92px;
  height: 29px;
  top: 880px;
  left: 870px;
  gap: 0px;
  border-radius: 6px;
  border: 0.5px 0px 0px 0px;
  /* opacity: 0px; */
  color: #038900;
  border: 0.5px solid #038900;
  background: #ecffec;
}

.chartButtonRejected{
    border: 0.5px solid #D90000;
    width: 92px;
height: 29px;
top: 949px;
left: 870px;
gap: 0px;
border-radius: 6px;
/* opacity: 0px; */
color: #D90000;

}
.display_flex_justify{
    display: flex;
    justify-content: space-between;
}

.mr_right_g{
    margin-right: 20px;
}