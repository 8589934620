.container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center; 
  }
  
  .hoverTarget {
    /* padding: 10px 20px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    cursor: pointer;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #333;
    position: relative; */
    color:red;
  }
  
  .tooltip {
    display: none;
    position: absolute;
    top: 100%; /* Position the tooltip below the hover target */
    left: -240px;
    transform: translateX(-50%);
    margin-top: 10px;
    width: 500px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 3px solid gray;
  }
  
  .hoverTarget:hover .tooltip {
    display: block;
  }
  
  .customTable {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-family: 'Arial', sans-serif;
  }
  
  .customTable th,
  .customTable td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .customTable th {
    background-color: #f1f5fa;
    color: #333;
    text-align: left;
    font-weight: bold;
    font-size: 1rem;
  }
  
  
  .customTable td {
    color: #444;
    text-align: left; /* Right-align text for numeric values */
  }
  
  .customTable td:first-child {
    text-align: left; /* Left-align the first column (Date) */
  }
  
  .customTable tr:hover {
    background-color: #f1f1f1;
  }
  