.inventoy-card {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 50px 0px #0000000d;
  border-radius: 26.67px 26.67px 26.67px 26.67px;
}

.item-code {
  border: 0.61px solid #2b2e63;
  border-radius: 10px;
  background: #f5f6ff;
  padding: 6px;
  height: 3.4rem;
}

.item-code h4 {
  color: #2b2e63;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

/* Add this to your global CSS file or inline using a styled-component */
.react-datepicker-popper {
  z-index: 1000; /* or any value higher than other elements */
}

.inventoryFilterInput {
  border: 1px solid rgb(204, 204, 204);
  background-color: transparent;
}
.inventoryFilterInput::placeholder {
  font-size: .5rem;
  color: #2b2e63;
}

.fontSize21{
  font-size: 1.3125rem;
}
.fontSize15{
  font-size: 0.9375rem;
}
.fontSize14{
  font-size: 0.875rem;
}
.textGrey{color:#A9A9A9}
.textGreen{color:#18AE00;

}
.textRed{color:#D90000;
 
}
 .logs-table table thead tr {
  background-color: rgb(192 192 192 / 18%);
  border-bottom: 5px solid red !important;
}