.toggleButton {
    margin-bottom: 10px;
    background: none;
    border: none;
  }
  
  .offcanvas {
    height: 100%; /* Adjust the height to match the design */
    max-height: none;
    width:30%;
    padding: 1.2rem;
  }
  
  .offcanvas-title {
    font-family: Poppins;
   font-size: 17.33px;
font-weight: 600;
line-height: 26px;
text-align: left;
background: #333333;

  }
  
  .formGroup {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .radioLabel {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color:#000000;
    
  }
  .radioInput {
    margin-right: .6rem;
  }
  
  .inputGroup {
    margin-bottom: 20px;
  }
  
  .inputLabel {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 15px;
    margin-top: 1rem;
 }
  
  .textInput {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: .3rem;
  }
  .textInput::placeholder {
    font-size: 14px; 
  }
  
  .logsTitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 2rem;
  }
  
  .logsTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .logsTable th,
  .logsTable td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  .logsTable th{
    font-size: 1rem;
    background-color: #f1f5fa;

  }
  
  
  
  .logsTable th:first-child,
  .logsTable td:first-child {
    text-align: left;
  }
  
  .textContent {
    margin-bottom: 20px;
    font-size: 14px;
    color: #555;
  }
  
  .updateButton {
    width: 100%;
    padding: 12px;
    background-color: #ff4b4b;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .updateButton:hover {
    background-color: #ff2b2b;
  }
  