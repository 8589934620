.edit_textarea{
    textarea{
        position: relative;
    }
    p{
        color: 
        #D90000;
        font-weight: 500;
    }
    .pencil_icon{
        position: absolute;
        top: 106px;
        right: 68px;
        cursor: pointer;
    
    }
}
.butn_conf{
    background-color: #D90000;
    padding: 0.4rem 1.5rem;
    border: 1px solid #D90000;
    color: white;
    border-radius: 0.4rem;
}

.stage-bg{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 0.6rem 1rem 1rem;
    background-color: #F4F4F4;
    p{
        font-size: 1.2rem;
    }
    button{
        background-color: #D90000;
        border: 1px solid #D90000;
        padding: 0.4rem 1.8rem;
        color: white;
        border-radius: 0.5rem;
    }
}