/////////// TENDER DASHBOARD  //////////////

.tender-dashboard {
  .fw-bold {
    font-weight: 550 !important;
  }
  

  .recharts-wrapper {
    width: 80% !important;
    height: 305px !important;
  }

  .heading-card {
    min-height: 18rem;
    max-height: 18rem;
    .tender-heading {
      margin-bottom: 1.5rem;
      h3 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
    p {
      margin-bottom: 1.5rem;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .detail-card {
    min-height: 8.5rem;
    max-height: 8.5rem;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    h5 {
      font-size: 1rem;
    }
    &:hover {
      background-color: #000444;
      p,
      h5,
      h3 {
        color: #fff !important;
      }
      img {
        filter: brightness(0) invert(1);
      }
    }
    .tender-type {
      h5 {
        min-height: 2.2rem;
        max-height: 2.2rem;
        margin: 0;
      }
    }
    .icon-img {
      img {
        min-height: 4rem;
        max-height: 4rem;
      }
    }
  }
}

.pie-chart {
  position: relative;
  .status-color {
    position: absolute;
    z-index: 10;
    right: 6px;
  }
  .chart-title {
    position: absolute;
    z-index: 10;
    font-weight: 600;
  }
}

.tooltip-inner {
  background-color: black;
  color: white;
  padding: 0.5rem;
  font-size: 16px;
  font-weight: 600;
}

///////// TENDER DASHBOARD END ///////////////

////// IFRAME In Tender List ///////////
iframe {
  img {
    width: 100%;
    object-fit: cover;
  }
}
/////// Iframe End ////////////

/////////Tender Card ////////////

.tender-card {
  border: 1px solid lightgray;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  transition: 0.2s ease-in-out;
  border-radius: 15px;
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .fw-bold {
    font-weight: 600 !important;
  }
  .tender-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tender-detail {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

///////// Tender Card End ///////////

////////// TABLE CSS USED IN TENDER , PROJECT and MATERIAL TRACKER ///////////

.table-container-scroll {
  width: 100%;
  overflow: auto;
  .tender-list-table {
    table-layout: fixed;
    border-top: 1px solid black;
    tr {
      border-bottom: 1px solid black;
      padding: 0;
      margin: 0;
    }
    p {
      min-width: 13rem;
      max-width: 13rem;
      word-break: break-word;
    }
    h6 {
      font-size: 1.1rem;
      margin: 0.5rem 0;
      font-weight: 600;
      min-width: 13rem;
      max-width: 13rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    td,
    th {
      border: 1px solid black;
      border-top: none;
    }
    td {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .alert-table {
    table-layout: fixed;
    tr {
      border-bottom: 1px solid black;
      padding: 0;
      margin: 0;
    }
    p {
      min-width: 13rem;
      max-width: 13rem;
      word-break: break-word;
      margin: 0;
    }
    h6 {
      font-size: 1.1rem;
      margin: 0.5rem 0;
      font-weight: 600;
      min-width: 13rem;
      max-width: 13rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    td,
    th {
      border: 1px solid black;
      border-top: none;
    }
    td {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .table thead {
    display: block;
  }

  .table tbody {
    display: block;
    overflow-y: scroll;
    max-height: 600px;
    padding: 0;
  }

  .table tbody::-webkit-scrollbar {
    width: 12px;
  }

  .table tbody::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  .table tbody::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}

////////// TABLE CSS USED IN TENDER , PROJECT and MATERIAL TRACKER END ///////////

////// DATEPICKER START /////////

.custom-datepicker-input {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  border: 1px solid red;
  svg {
    margin-right: 0.5rem;
  }
}

//// Select Seacrh start ////
.react-select {
  .css-b62m3t-container {
    padding: 0;
    // border-color:#8d93a1!important;
  }
  .css-13cymwt-control {
    border: 1px solid gray !important;
  }
  .css-1fdsijx-ValueContainer {
    padding-top: 0;
    padding-bottom: 0;
  }
  .css-qbdosj-Input {
    padding: 0;
    margin: 0;
  }
  #react-select-3-placeholder {
    color: #000444 !important;
  }
}

.tender-ticket {
  .form-control,
  .form-select {
    border: 1px solid gray !important;
    padding: 7px 10px;
  }
  .form-select-control {
    border: 1px solid gray !important;
    border-radius: 4px;
  }
}

///// Accordian css /////

.accordion {
  .accordion-button {
    background-color: #e3e3e33b;
  }
  .accordion-button:not(.collapsed) {
    color: #d03438;
    background-color: rgba(208, 52, 56, 0.1019607843);
  }
}

//// Tender Kanban ///


.tender-kanban {
  .kanban-main-card {
    .status-dropdown {
      button {
        padding: 0.25rem 0.5rem !important;
        background-color: #f7f7f7 !important;
        border: 1px solid #f7f7f7 !important;
        color: black !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 15rem;
        cursor: auto;
      }
      .dropdown-toggle-split {
        padding: 0.25rem 1rem !important;
        cursor: pointer !important;
      }
      .dropdown-menu {
        border: 1px solid gray;
        padding: 0;
        .dropdown-item {
          padding: 0.5rem 1rem;
          border-bottom: 1px solid gray !important;
        }
      }
    }

    .next-stage-btn {
      background-color: white;
      width: 100%;
      text-align: center;
      padding: 0.4rem 0;
      border: 1px solid;
      transition: 0.2s ease-in-out;
      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
}

////// MODAL WIDTH ////////

.alert-modal {
  .modal-lg {
    max-width: 1100px;
  }
}

.attachment-modal{
  .modal-lg{
    max-width: 1060px;
  }
}

//Track-my-work-styling

.tender-custom-tabs .nav-link.active {
  border: none;
  box-shadow: none;
  text-decoration: none;
  background-color: none;

}

.track-my-work-heading{
  background-color: #F4F4FB;
  .track-my-work-heading th{
    font-size: 14px;
    color: #8B909A;
  }
}
.track-my-work-body{
  .track-my-work-data{
    font-size: 16px;
    color:#000000;
    border-bottom: 1px solid #ECECEC;
  }
}
.track-my-work-heading th:nth-child(1) {
  width: 8%;
  color:#8B909A;
}

.track-my-work-heading th:nth-child(2) {
  width: 15%;
  color:#8B909A;
}
.track-my-work-heading th:nth-child(3) {
  width: 12%;
  color:#8B909A;
}
.track-my-work-heading th:nth-child(4) {
  width: 12%;
  color:#8B909A;
}
.track-my-work-heading th:nth-child(5) {
  width: 19%;
  color:#8B909A;
}
.track-my-work-heading th:nth-child(6) {
  width: 10%;
  color:#8B909A;
}
.track-my-work-heading th:nth-child(7) {
  width: 12%;
  color:#8B909A;
}