 /* .accordion .accordion-button:not(.collapsed) {
    color: black !important;
    background: white !important;
    border-color: white !important;
}
.accordion .accordion-button {
    color: black !important;
    background: white !important;
    border-color: white !important;
}

.table th {
    color: #0f172a;
    font-size: 0.9rem;
    font-weight: 400 !important;
} */

.tabsall .nav.nav-tabs .nav-item.show.active, .nav.nav-tabs .nav-item.show:focus, .nav.nav-tabs .nav-link.active, .nav.nav-tabs .nav-link:focus {
    color: #d03438;
   
    background-color: 
    #EEEEEE;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0.4rem 2rem;
}

.tabsall .nav.nav-tabs .nav-item.show, .nav.nav-tabs .nav-link {
    color: #303e67;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0.4rem 2rem;
    
}
.table-uniq table{
    border: none;
}
.table-uniq table thead tr {
    background-color: white;
    border-bottom:none;
}


.table-uniq table th {
    color: #7c7c7c;
    font-size: 0.9rem;
    font-weight: 600 !important;
    padding: 0.2rem 1.5rem;
}
.table-uniq data{
    font-size: 0.9rem;
    color: black;
}


.accordien_uniq .accordion .accordion-button:not(.collapsed) {
    color: #c7c7c7;
    border: 0;
    background-color: rgb(255 255 255);
    border: none;
}


.accordien_uniq .accordion .accordion-button {
    background-color: rgb(255 255 255);
}
.form_goodsnote input{
padding: 0.5rem;
border: 1px solid
#8D93A1;
width: 100% !important;
}


.table tbody{
    box-shadow: rgba(91, 105, 165, 0.2) 0px 0px 7px;
    line-height: 1.7rem;
    border: 1px solid white;

}


.search_matarial .form-select-control{
    width: 70%;
}
    

.add-grn-input {
    margin-bottom: 1rem;
}

.grn-table-container {
    margin: 0 20px 0 0;
    border-radius: 8px;

}
  
  .grn-table-container h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .grn-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .grn-table th, .grn-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .grn-table th {
    background-color: #f1f5fa;
    font-size: 14px;
    font-weight: 700;
  }
  
  .grn-table td input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
/* Grn multiple Select Changes */
.goodsReceiptNoteMultiSelect .MuiSelect-select{
  padding: 9px;
}

  /* goods-reciept-note-tabs-styling-byAnu */

  /* .goods-custom-tabs {
    font-size: 25px !important;
    color: #303E67 !important;
    margin-left: 12rem !important;
   
    
  }
  .nav-tabs .nav-link.active {
    color: #303E67 !important;
    font-weight: bold;
    border-bottom-width: 4px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #244595 !important;
  } */

  .inspection-table-container {
    margin: 0 20px 0 0;
    border-radius: 8px;
    background-color: #ccc !important;
  }
  
  .inspection-table-container h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .inspection-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .inspection-table th, .inspection-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .inspection-table th {
    background-color: #f1f5fa;
    font-size: 14px;
    font-weight: 700;
  }
  
  .inspection-table td input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  

