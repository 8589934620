.tender-dashboard .fw-bold {
  font-weight: 550 !important;
}
.tender-dashboard .recharts-wrapper {
  width: 80% !important;
  height: 305px !important;
}
.tender-dashboard .heading-card {
  min-height: 18rem;
  max-height: 18rem;
}
.tender-dashboard .heading-card .tender-heading {
  margin-bottom: 1.5rem;
}
.tender-dashboard .heading-card .tender-heading h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}
.tender-dashboard .heading-card p {
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: 16px;
}
.tender-dashboard .detail-card {
  min-height: 8.5rem;
  max-height: 8.5rem;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.tender-dashboard .detail-card h5 {
  font-size: 1rem;
}
.tender-dashboard .detail-card:hover {
  background-color: #000444;
}
.tender-dashboard .detail-card:hover p,
.tender-dashboard .detail-card:hover h5,
.tender-dashboard .detail-card:hover h3 {
  color: #fff !important;
}
.tender-dashboard .detail-card:hover img {
  filter: brightness(0) invert(1);
}
.tender-dashboard .detail-card .tender-type h5 {
  min-height: 2.2rem;
  max-height: 2.2rem;
  margin: 0;
}
.tender-dashboard .detail-card .icon-img img {
  min-height: 4rem;
  max-height: 4rem;
}

.pie-chart {
  position: relative;
}
.pie-chart .status-color {
  position: absolute;
  z-index: 10;
  right: 6px;
}
.pie-chart .chart-title {
  position: absolute;
  z-index: 10;
  font-weight: 600;
}

.tooltip-inner {
  background-color: black;
  color: white;
  padding: 0.5rem;
  font-size: 16px;
  font-weight: 600;
}

iframe img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.tender-card {
  border: 1px solid lightgray;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  transition: 0.2s ease-in-out;
  border-radius: 15px;
}
.tender-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.tender-card .fw-bold {
  font-weight: 600 !important;
}
.tender-card .tender-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tender-card .tender-detail {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-container-scroll {
  width: 100%;
  overflow: auto;
}
.table-container-scroll .tender-list-table {
  table-layout: fixed;
  border-top: 1px solid black;
}
.table-container-scroll .tender-list-table tr {
  border-bottom: 1px solid black;
  padding: 0;
  margin: 0;
}
.table-container-scroll .tender-list-table p {
  min-width: 13rem;
  max-width: 13rem;
  word-break: break-word;
}
.table-container-scroll .tender-list-table h6 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
  font-weight: 600;
  min-width: 13rem;
  max-width: 13rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-container-scroll .tender-list-table td,
.table-container-scroll .tender-list-table th {
  border: 1px solid black;
  border-top: none;
}
.table-container-scroll .tender-list-table td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.table-container-scroll .alert-table {
  table-layout: fixed;
}
.table-container-scroll .alert-table tr {
  border-bottom: 1px solid black;
  padding: 0;
  margin: 0;
}
.table-container-scroll .alert-table p {
  min-width: 13rem;
  max-width: 13rem;
  word-break: break-word;
  margin: 0;
}
.table-container-scroll .alert-table h6 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
  font-weight: 600;
  min-width: 13rem;
  max-width: 13rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-container-scroll .alert-table td,
.table-container-scroll .alert-table th {
  border: 1px solid black;
  border-top: none;
}
.table-container-scroll .alert-table td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.table-container-scroll .table thead {
  display: block;
}
.table-container-scroll .table tbody {
  display: block;
  overflow-y: scroll;
  max-height: 600px;
  padding: 0;
}
.table-container-scroll .table tbody::-webkit-scrollbar {
  width: 12px;
}
.table-container-scroll .table tbody::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.table-container-scroll .table tbody::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-datepicker-input {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  border: 1px solid red;
}
.custom-datepicker-input svg {
  margin-right: 0.5rem;
}

.react-select .css-b62m3t-container {
  padding: 0;
}
.react-select .css-13cymwt-control {
  border: 1px solid gray !important;
}
.react-select .css-1fdsijx-ValueContainer {
  padding-top: 0;
  padding-bottom: 0;
}
.react-select .css-qbdosj-Input {
  padding: 0;
  margin: 0;
}
.react-select #react-select-3-placeholder {
  color: #000444 !important;
}

.tender-ticket .form-control,
.tender-ticket .form-select {
  border: 1px solid gray !important;
  padding: 7px 10px;
}
.tender-ticket .form-select-control {
  border: 1px solid gray !important;
  border-radius: 4px;
}

.accordion .accordion-button {
  background-color: rgba(227, 227, 227, 0.231372549);
}
.accordion .accordion-button:not(.collapsed) {
  color: #d03438;
  background-color: rgba(208, 52, 56, 0.1019607843);
}

.tender-kanban .kanban-main-card .status-dropdown button {
  padding: 0.25rem 0.5rem !important;
  background-color: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
  color: black !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
  cursor: auto;
}
.tender-kanban .kanban-main-card .status-dropdown .dropdown-toggle-split {
  padding: 0.25rem 1rem !important;
  cursor: pointer !important;
}
.tender-kanban .kanban-main-card .status-dropdown .dropdown-menu {
  border: 1px solid gray;
  padding: 0;
}
.tender-kanban .kanban-main-card .status-dropdown .dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid gray !important;
}
.tender-kanban .kanban-main-card .next-stage-btn {
  background-color: white;
  width: 100%;
  text-align: center;
  padding: 0.4rem 0;
  border: 1px solid;
  transition: 0.2s ease-in-out;
}
.tender-kanban .kanban-main-card .next-stage-btn:hover {
  background-color: black;
  color: white;
}

.alert-modal .modal-lg {
  max-width: 1100px;
}

.attachment-modal .modal-lg {
  max-width: 1060px;
}

.tender-custom-tabs .nav-link.active {
  border: none;
  box-shadow: none;
  text-decoration: none;
  background-color: none;
}

.track-my-work-heading {
  background-color: #F4F4FB;
}
.track-my-work-heading .track-my-work-heading th {
  font-size: 14px;
  color: #8B909A;
}

.track-my-work-body .track-my-work-data {
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid #ECECEC;
}

.track-my-work-heading th:nth-child(1) {
  width: 8%;
  color: #8B909A;
}

.track-my-work-heading th:nth-child(2) {
  width: 15%;
  color: #8B909A;
}

.track-my-work-heading th:nth-child(3) {
  width: 12%;
  color: #8B909A;
}

.track-my-work-heading th:nth-child(4) {
  width: 12%;
  color: #8B909A;
}

.track-my-work-heading th:nth-child(5) {
  width: 19%;
  color: #8B909A;
}

.track-my-work-heading th:nth-child(6) {
  width: 10%;
  color: #8B909A;
}

.track-my-work-heading th:nth-child(7) {
  width: 12%;
  color: #8B909A;
}/*# sourceMappingURL=TenderStyle.css.map */