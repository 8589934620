.lotsLogsThead {
  background: #d9e0ef;
  min-height: 3rem;
}
.lotsLogsThead th {
  padding: 0.5rem;
}
.lotsLogsTbody td {
  padding: 0.5rem;
  border-right: 1.36px solid #dbdfea;
}
.lotDetailHeader {
  height: 55px;
  border-radius: 15px 15px 0px 0px;
  background: #0f172a;
  display: flex;
  align-items: center;
}
.lotDetailHeader p {
  color: white;
  margin-bottom: 0;
  padding: 1rem;
}
.lotDetailHeader span {
  background: #ffffff;
  padding: 0.3rem;
  border-radius: 3.12px 0px 0px 0px;
}
.lotDetailHeader p:nth-last-child(1) {
  margin-left: auto;
}
