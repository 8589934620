.table-container-scroll {
  width: 100%;
  overflow: auto;
}
.table-container-scroll .project-list-table {
  table-layout: fixed;
}
.table-container-scroll .project-list-table tr {
  border-bottom: 1px solid lightgrey;
  padding: 0;
  margin: 0;
}
.table-container-scroll .project-list-table p {
  min-width: 13rem;
  max-width: 13rem;
  margin: 0;
}
.table-container-scroll .project-list-table h6 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
  font-weight: 600;
  min-width: 13rem;
  max-width: 13rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-container-scroll .project-list-table td,
.table-container-scroll .project-list-table th {
  border: 1px solid lightgrey;
  border-top: none;
}
.table-container-scroll .material-list-table {
  table-layout: fixed;
}
.table-container-scroll .material-list-table p {
  min-width: 13rem;
  max-width: 13rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.table-container-scroll .material-list-table h6 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
  font-weight: 600;
  min-width: 13rem;
  max-width: 13rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-container-scroll .table thead {
  display: block;
}
.table-container-scroll .table tbody {
  display: block;
  overflow-y: scroll;
  max-height: 600px;
  padding: 0;
}
.table-container-scroll .table tbody::-webkit-scrollbar {
  width: 12px;
}
.table-container-scroll .table tbody::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.table-container-scroll .table tbody::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}/*# sourceMappingURL=TableStyle.css.map */